// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-news-strapi-blogs-slug-js": () => import("./../../../src/pages/news/{strapiBlogs.Slug}.js" /* webpackChunkName: "component---src-pages-news-strapi-blogs-slug-js" */),
  "component---src-pages-pages-strapi-pages-slug-js": () => import("./../../../src/pages/pages/{strapiPages.Slug}.js" /* webpackChunkName: "component---src-pages-pages-strapi-pages-slug-js" */),
  "component---src-pages-products-strapi-products-slug-js": () => import("./../../../src/pages/products/{strapiProducts.Slug}.js" /* webpackChunkName: "component---src-pages-products-strapi-products-slug-js" */),
  "component---src-pages-risks-strapi-risk-slug-js": () => import("./../../../src/pages/risks/{strapiRisk.Slug}.js" /* webpackChunkName: "component---src-pages-risks-strapi-risk-slug-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

